#content {
  .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // This is for a 16:9 aspect ratio. Adjust as needed.
  }
  .col {
    margin-bottom: 5.5rem;
  }
  h2 {
    color: white;
    margin-top: 1rem;
    font-family: Helvetica, sans-serif;

    @media (max-width: 1200px) {
      font-size: 1.5rem; // Adjust this value to your preference for large screens
    }

    @media (max-width: 992px) {
      font-size: 1.25rem; // Adjust this value to your preference for medium screens
    }

    @media (max-width: 768px) {
      font-size: 1rem; // Adjust this value to your preference for small screens
    }

    @media (max-width: 576px) {
      font-size: 0.875rem; // Adjust this value to your preference for extra small screens
    }
  }
}
