@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
  background-color: black;
  background-image: url("./Content/background.png");
}

.midground {
  background-image: url("./Content/midground.png");
  background-attachment: fixed; // Makes the midground background image scroll with the content
}

/* Width */
::-webkit-scrollbar {
  width: 0.4rem;
}

/* Oval Handle */
::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 5rem;
}

/* Black Track */
::-webkit-scrollbar-track {
  background-color: #080808;
}
