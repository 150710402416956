.video-component {
  position: relative;
}

.thumbnail {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; // This is for a 16:9 aspect ratio. Adjust as needed.
  cursor: pointer;

  .thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: 0.8;
}

.play-button:hover {
  opacity: 1;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
