#header {
  width: 100%;
  padding: 2.5rem 1rem 1rem 1rem;
  .header-title {
    height: 9rem;
  }
}

#icon-row {
  display: flex;
  justify-content: center;
  img {
    margin: 1rem;
    width: 40px;
    background-color: white;
    border-radius: 100%;
    border: 1px solid white;
  }
}
